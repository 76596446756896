<template lang="pug">
  .row
    .col-12(class="lg:col-8 md:col-7")
      div(class="bg-primary overflow-hidden relative h-550p")
        .map-background(class="absolute")
          img(:src="mapBackground")

          div(v-for="institution in institutions.data" class="absolute" :class="institutionClasses(institution)")
            div(class="cursor-pointer" @mouseenter="enterActiveMarker(institution.id)" @mouseleave="leaveActiveMarker(institution.id)" @click="setActivePopup(institution.id)")
              img(v-if="institution.attributes.is_headquarter" :src="headquarterMarker")
              map-marker-active(v-else :active="activeMarker === institution.id")

            transition(name="map-popup" mode="out-in")
              a.map-popup(v-if="activePopup === institution.id" :href="'#'" class="p-8 z-10 w-380p" :class="{'-translate-x-50': institution.attributes.param != 'escondido'}" v-on-clickaway="(event) => { initMarker(event, institution.id) }")
                .p--sm Lisi Medical
                .h2(class="mt-2 mb-4") {{ institution.attributes.title }}
                p.map-popup__content.mb-5(v-html="institution.attributes.teaser")
                .link-graydark-primary.link-arrow
                  span(class="tracking-wide") {{ institution.attributes.phone }}

    .col-12(class="lg:col-4 md:col-5")
      ul(class="list-reset transition")
        li(v-for="institution in institutions.data" class="cursor-pointer pb-16" @mouseenter="setActiveMarker(institution.id)" @mouseleave="setActiveMarker(0)", @click="setActivePopup(institution.id)")
          .p--sm(class="transition text-gray-dark" :class="{'text-gray': activeMarker !== 0}") Lisi Medical
          .h2(class="transition" :class="classEnterpriseList(institution.id)") {{ institution.attributes.title }}

</template>

<script>
import map from "images/map/map-enterprise.png"
import headquarterMarker from "images/map/headquarter.png"

import MapMarkerActive from "@components/MapMarkerActive"
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  components: {
    MapMarkerActive
  },
  mixins: [clickaway],

  props: {
    translations: {
      type: Object,
      required: true
    },
    institutions: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      mapBackground: map,
      headquarterMarker: headquarterMarker,
      activeMarker: 0,
      activePopup: 0
    }
  },

  methods: {
    classEnterpriseList (id) {
      return {
        'text-gray-dark': this.activeMarker !== id,
        'text-gray': this.activeMarker !== 0 && this.activeMarker !== id,
        'text-primary': this.activeMarker === id || this.activePopup === id
      }
    },

    institutionClasses (institution) {
      const classes = [
        { active: this.activePopup === institution.id },
        `marker-${institution.attributes.param}`
      ]

      if (institution.attributes.is_headquarter) {
        classes.push({ 'active-marker': this.activeMarker === institution.id })
      }

      return classes
    },

    setActiveMarker (id) {
      this.activeMarker = id
    },

    enterActiveMarker (id) {
      if (this.activeMarker === 0) {
        this.activeMarker = id
      }
    },

    setActivePopup (id) {
      this.activePopup = id
      this.activeMarker = id
    },

    leaveActiveMarker (id) {
      if (this.activePopup !== id && this.activeMarker === id) {
        this.activeMarker = 0
      }
    },

    initMarker (event, id) {
      if (this.activePopup === id) {
        this.setActiveMarker(0)
        this.setActivePopup(0)
      }
    }
  }
}
</script>

<style scoped>
  .map-popup-enter-active,
  .map-popup-leave-active{
    transition: opacity 350ms ease;
  }

  .map-popup-enter-active::before,
  .map-popup-leave-active::before{
      transition: height 450ms cubic-bezier(.4,0,.2,1);
  }

  .map-popup-enter,
  .map-popup-leave-to{
    opacity: 0;
  }

  .map-popup-enter::before,
  .map-popup-leave-to::before{
    height: 0%;
  }

  .map-popup-enter-to,
  .map-popup-leave {
    opacity: 1;
  }

  .map-popup-enter-to::before,
  .map-popup-leave::before{
    height: 100%;
  }
</style>
