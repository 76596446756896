import axios from 'axios'

const state = {
  steps: {},
  contact: {},
  showContact: false,
  errors: [],
  endpoint: '/api/v1/prototypes',
  file: null
}

const mutations = {
  setSteps (state, payload) {
    state.steps = payload
  },

  updateStep (state, payload) {
    state.steps = { ...state.steps, [payload.key]: payload.value }

    localStorage.setItem('prototype_steps', JSON.stringify(state.steps))
  },

  clearSteps (state) {
    localStorage.removeItem('prototype_steps')
    state.steps = {}
  },

  showContact (state) {
    state.showContact = true

    localStorage.setItem('show_contact', true)
  },

  hideContact (state) {
    state.showContact = false

    localStorage.removeItem('show_contact')
  },

  setContact (state, payload) {
    state.contact = payload
  },

  setFile (state, payload) {
    state.file = payload
  },

  setErrors (state, payload) {
    state.errors = payload
  }
}

const getters = {
  steps (state) {
    return state.steps
  },

  showContact (state) {
    return state.showContact
  },

  file (state) {
    return state.file
  },

  errors (state) {
    return state.errors
  }
}

const actions = {
  async submitPrototypeRequest ({ commit }) {
    const formData = new FormData()

    Object.keys(state.contact).forEach(key => formData.append(`prototype[${key}]`, state.contact[key]))
    Object.keys(state.steps).forEach(key => formData.append(`prototype[informations][${key}]`, state.steps[key]))
    if (state.file) {
      formData.append('prototype[document_attributes][asset]', state.file)
    }

    return axios({
      method: 'POST',
      url: state.endpoint,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(({ data }) => {
        commit('setErrors', [])
        commit('hideContact')

        return data
      })
      .catch(({ response: { data } }) => {
        const errors = data.errors.data.attributes.errors

        commit('setErrors', errors)
        return Promise.reject(errors)
      })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
