/* eslint no-console:0 */

// Polyfill =====================================================================
import "intersection-observer"

// MODULES =====================================================================
import Rails from 'rails-ujs'
import SmoothScroll from 'smooth-scroll'
import "@appPages/vue"

import "stylesheets/application.sass"
import CustomFileInputList from "@utils/custom-file-input-list"
import ScrollTop from "@utils/scroll-top"
import InputSmooth from "@utils/input-smooth"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import HeaderMenu from '@utils/header-menu'
import InfoBubble from '@utils/info-bubble'
import NavActive from '@utils/nav-active'
import Parallax from "@utils/parallax"
import AnimateScroll from "@utils/animate-scroll"
import Video from "@utils/video"

// Fonts ===============================================================
import "typeface-source-sans-pro"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage

window.recaptachCallback = () => {
  document.querySelector("[data-is-recaptcha-form]").submit()
}

document.addEventListener("DOMContentLoaded", () => {
  new InputSmooth()
  new ImageObjectFit()
  new CustomFileInputList()
  new ScrollTop()
  new HeaderMenu()
  new InfoBubble()
  new Parallax()
  new AnimateScroll()
  new NavActive()
  new Video()

  new SmoothScroll('a[href*="#"]', {
    speed: 450
  })

  if (document.querySelector(".error_explanation") && document.querySelector("#job-application-form-anchor") !== null) {
    location.hash = '#job-application-form-anchor'
  }
})
