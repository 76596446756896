<template lang="pug">
  div
    img(:src="mapMarkerActive" v-if="active")
    img(:src="mapMarker" v-else)
</template>

<script>

import marker from "images/map/map-marker.svg"
import markerActive from "images/map/map-marker-white.svg"

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      mapMarker: marker,
      mapMarkerActive: markerActive
    }
  }
}
</script>
