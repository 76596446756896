export default class InfoBubble {
  // constructeur
  constructor () {
    this.$element = $('[data-is-info-bubble]')
    this.$elementToggler = $('[data-is-info-bubble] .info__close')
    this.bindEvents()
  }

  bindEvents () {
    const self = this
    const documentHeight = $(document).height()
    const windowHeight = $(window).height()

    setTimeout(function () {
      return self.$element.addClass('active')
    }
    , 3000)

    $(window).scroll(function () {
      if ($(this).scrollTop() < (documentHeight - windowHeight - 489)) {
        return self.$element.addClass('active')
      } else {
        return self.$element.removeClass('active')
      }
    })

    self.$elementToggler.on('click', function () {
      self.$element.addClass('delete')
    })
  }
}
