<template lang="pug">
div
  slot(:handleChange="handleChange" :profile="profile")
</template>

<script>
export default {
  props: {
    initialProfile: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      profile: this.initialProfile
    }
  },

  methods: {
    handleChange (e) {
      this.profile = e
    }
  }
}
</script>
