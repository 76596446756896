import Vue from 'vue'
import store from '@store'
import Lightbox from 'vue-my-photos'
import vSelect from 'vue-select'
import VueTabs from 'vue-nav-tabs/dist/vue-tabs.js'
import { Dropdown } from 'bootstrap-vue/es/components'
import PrettyCheckbox from 'pretty-checkbox-vue'
import Transitions from 'vue2-transitions'
import VueScrollProgressBar from '@guillaumebriday/vue-scroll-progress-bar'

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().forEach(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`./${component}`))
})

// Components
Vue.component('VSelect', vSelect)
Vue.component('Lightbox', Lightbox)
Vue.component('PrototypeWrapper', () => import(/* webpackChunkName: "prototype" */ '@components/Prototype/PrototypeWrapper'))
Vue.component('PrototypeForm', () => import(/* webpackChunkName: "prototype" */ '@components/Prototype/PrototypeForm'))
Vue.component('FormError', () => import('@components/FormError'))
Vue.component('Slick', () => import('@components/Slick'))
Vue.component('ThumbModalList', () => import('@components/ThumbModalList'))
Vue.component('LightView', () => import('@components/LightView'))
Vue.component("VueScrollProgressBar", VueScrollProgressBar)

// Plugins
Vue.use(VueTabs)
Vue.use(PrettyCheckbox)
Vue.use(Dropdown)
Vue.use(Transitions)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app',
    store
  })
})
