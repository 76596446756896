import 'waypoints/lib/noframework.waypoints.js'

export default () => {
  const elements = document.querySelectorAll('[data-animate]')

  elements.forEach(el => {
    new window.Waypoint({
      element: el,
      handler: function (direction) {
        if (direction === 'down') {
          el.classList.add('in')
        } else if (el.dataset.animate === 'both') {
          el.classList.remove('in')
        }
      },
      offset: '80%'
    })
  })
}
