export default class HeaderMenu {
  // constructeur
  constructor () {
    this.openMenu = document.querySelector('[data-open-menu]')
    this.closeMenu = document.querySelector('[data-close-menu]')
    this.menu = document.querySelector('[data-is-menu]')
    this.menuWrapper = document.querySelector('[data-is-menu-wrapper]')
    this.toggleSubmenu = document.querySelectorAll('[data-toggle-submenu]')

    this.bindEvents()
  }

  bindEvents () {
    this.openMenu.addEventListener('click', (e) => {
      e.preventDefault()
      this.menu.classList.add('active')
      this.menuWrapper.classList.add('active')
    })

    this.closeMenu.addEventListener('click', (e) => {
      e.preventDefault()
      this.menu.classList.remove('active')
      this.menuWrapper.classList.remove('active')
    })

    Array.prototype.forEach.call(this.toggleSubmenu, function (el, i) {
      el.addEventListener('click', () => {
        el.nextElementSibling.classList.toggle('active')
      })
    })
  }
}
