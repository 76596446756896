export default class NavActive {
  constructor () {
    this.menuWrapper = document.querySelector('[data-is-scroll-nav]')
    this.menu = document.querySelector('[data-is-scroll-menu]')
    this.sections = document.querySelector('[data-is-scroll-section]')

    this.offset = 128 // header height
    const originalScroll = window.scrollY
    this.class = 'active'

    if (this.menuWrapper && this.sections) {
      this.menuLinks = this.menuWrapper.querySelectorAll('a')
      this.position = this.menuWrapper.getBoundingClientRect().top - this.offset + originalScroll
      this.bindEvents()
    }
  }

  // bind events
  bindEvents () {
    $(window).on('scroll', () => {
      this.scrollLevel()
      if (window.scrollY > this.position) {
        this.menuWrapper.classList.add(this.class)
      } else {
        this.menuWrapper.classList.remove(this.class)
      }
    })
  }

  // scroll level
  scrollLevel () {
    const self = this
    const scrollPos = window.scrollY

    Array.prototype.forEach.call(this.menuLinks, function (el, i) {
      const refElement = document.getElementById(el.getAttribute('href').substring(1))

      if (refElement.offsetTop - self.offset <= scrollPos && refElement.offsetTop + refElement.offsetHeight > scrollPos) {
        Array.prototype.forEach.call(self.menuLinks, function (el, i) {
          el.classList.remove('active')
        })
        el.classList.add('active')
        self.menu.scrollTo(el.offsetLeft - 20, 0)
      } else {
        el.classList.remove('active')
      }
    })
  }
}
