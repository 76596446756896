<template lang="pug">
  div
    div(class="bg-primary overflow-hidden relative h-420p sm:h-450p"  v-on-clickaway="initMarker")
      .map-background(class="absolute")
        img(:src="mapBackground" class="w-full h-auto")

        div(v-for="institution in institutions.data" class="absolute" :class="[{active: activeMarker === institution.id}, `marker-${institution.attributes.param}`]")
          img(:src="mapMarker" @click="setActiveMarker(institution.id)")
          .map-popup(class="p-8 z-10 w-260p" :class="{'-translate-x-50': institution.attributes.param != 'escondido'}")
            i.fal.fa-times(class="text-gray text-lg absolute pin-r pin-t p-5" @click="initMarker")
            a(:href="'#'" class="text-gray-dark")
              .p--sm Lisi Medical
              .h2(class="mt-2 mb-4") {{ institution.attributes.title }}
              p.mb-10(v-html="institution.attributes.teaser")
              .link-graydark-primary.link-arrow
                span(class="tracking-wide") {{ institution.attributes.phone }}

</template>

<script>

import { mixin as clickaway } from 'vue-clickaway2'

import marker from "images/map/map-marker.svg"
import map from "images/map/map-usa.png"

export default {
  mixins: [clickaway],

  props: {
    institutions: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      mapBackground: map,
      mapMarker: marker,
      activeMarker: 0
    }
  },

  methods: {
    setActiveMarker (id) {
      if (this.activeMarker === id) {
        this.activeMarker = 0
      } else {
        this.activeMarker = id
      }
    },
    // method for clickaway
    initMarker () {
      this.setActiveMarker(0)
    }
  }
}
</script>
