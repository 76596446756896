export default () => {
  const sentinel = document.querySelector('#sentinel')
  const scrollTop = document.querySelector('[data-is-scroll-top]')

  if (scrollTop === null) return

  const intersectionObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      scrollTop.classList.toggle('visible', !entry.isIntersecting)
    })
  }, {
    rootMargin: "100px"
  })

  intersectionObserver.observe(sentinel)

  scrollTop.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })
}
