export default () => {
  const videos = document.querySelectorAll('[data-video-iframe]')

  function setFormat () {
    videos.forEach((video) => {
      const format = video.offsetWidth * 9 / 16
      video.style.height = Math.round(format) + "px"
    })
  }

  setFormat()

  window.addEventListener("resize", () => {
    setFormat()
  })
}
