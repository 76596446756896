<template lang="pug">
div
  .row
    .col-12(class="lg:col-6 mb-6")
      v-select(
        v-model="selectedOption"
        :options="options"
        :searchable="false"
        :placeholder="placeholder"
      )

  slot(:selectedOption="selectedOption")
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      selectedOption: null
    }
  }
}
</script>
